import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

const BlogPage = ({ data }) => {
    const posts = data.allMdx.edges

    return (
        <Layout pageTitle="Blog">
            <div className="px-5">
                {
                    posts.map(({ node, idx }) => (
                        <article key={node.id}>
                            <h2>
                                <Link to={`/blog/${node.slug}`}>
                                    {node.frontmatter.title}
                                </Link>
                            </h2>
                            <small className="text-muted">
                                {node.frontmatter.date}
                            </small>
                            <p className="py-2">
                                {node.frontmatter.description || node.excerpt}
                                <span className="px-2">
                                    <Link to={`/blog/${node.slug}`}>
                                        [read more...]
                                    </Link>
                                </span>
                            </p>
                        </article>
                    ))
                }
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {fileAbsolutePath: {regex: "\\/blog/"}}
        ) {
            edges {
                node {
                    id
                    slug
                    frontmatter {
                        date
                        title
                        description
                    }
                }
            }
        }
    }
`

export default BlogPage